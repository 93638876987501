  <template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col>
        <v-card
          elevation=0
          class="bordered-item"
          :disabled="!canUpdate"
        >
          <v-card-text>
            <span>{{$t('t.ContactsToNotifyOnCreateClose')}}</span>
            <user-picker
              :can-close-chip="collaborationTask.canUpdate && !statusWillCloseCollaboration"
              :placeholder="`${$t('t.AddContact')}...`"
              :selection.sync="confirmations"
              :scope="accountScope"
              :document-types="['users', 'account-contacts']"
              :readonly="!canUpdate"
              :external-user-cache-type="externalUserCacheType"
              has-email
            >
              <!-- <template v-slot:item="{parent, item}">
                <v-list-item-icon>
                  <v-icon>{{item.group ? $icon('i.Users') : $icon('i.User')}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-html="parent.genFilteredText(`${item.fullname} (${item.group ? item.group.name : 'Contact'})`)" />
                </v-list-item-content>
              </template> -->
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    {{$t('t.NoConfirmationContactsAvailables')}}
                  </v-list-item-content>
                </v-list-item>
              </template>
            </user-picker>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation=0
          class="bordered-item"
          :disabled="!canUpdate"
        >
          <v-card-text>
            <v-row>
              <v-col class="d-flex align-baseline">
                <span>{{$t('t.ContactsToNotifyAt')}}</span>
                <v-text-field
                  type="number"
                  class="ml-2 mr-1 days-text"
                  dense
                  hide-details
                  :rules="[daysBeforeNotificationIsValid]"
                  v-model="nbDaysBeforeNotification"
                />
                <span class="ml-1">{{$t('t.DayOfTargetDate')}}</span>
                <span
                  v-if="collaborationTask.isReminderSent && collaborationTask.reminders.length"
                  class="ml-10"
                >
                  <v-icon
                    color="success"
                    class="mr-1"
                  >{{$icon("i.Checked")}}</v-icon>
                  {{$t("t.IsReminderSent")}}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <user-picker
                  :selection.sync="reminders"
                  :scope="accountScope"
                  :document-types="['users', 'account-contacts']"
                  :can-close-chip="collaborationTask.canUpdate && !statusWillCloseCollaboration"
                  :placeholder="`${$t('t.AddContact')}...`"
                  :readonly="!canUpdate"
                  :external-user-cache-type="externalUserCacheType"
                  has-email
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <span>{{$t('t.NoConfirmationContactsAvailables')}}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </user-picker>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { CacheType } from '@/wasm/pkg'
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    UserPicker: () => import('@/components/user-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.CollaborationTaskNotificationRecipientRef
    }
  },
  computed: {
    confirmations: {
      get () {
        return this.collaborationTask.confirmations
      },
      set (confirmations) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { confirmations }))
      }
    },
    nbDaysBeforeNotification: {
      get () {
        return this.collaborationTask.nbDaysBeforeNotification
      },
      set (nbDaysBeforeNotification) {
        if (typeof nbDaysBeforeNotification === 'string') {
          nbDaysBeforeNotification = parseInt(nbDaysBeforeNotification)
        }
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { nbDaysBeforeNotification }))
      }
    },
    reminders: {
      get () {
        return this.collaborationTask.reminders
      },
      set (reminders) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { reminders }))
      }
    },
    accountScope () {
      return Scope.account(this.collaborationTask.accountId)
    },
    daysBeforeNotificationIsValid () {
      if (!this.reminders?.length) { return true }
      const date = this.moment().add(this.collaborationTask.nbDaysBeforeNotification, 'days').format('YYYY-MM-DD')
      return this.collaborationTask.nbDaysBeforeNotification >= 0 && date <= this.collaborationTask.targetDate
    }
  },
  props: {
    collaborationTask: Object,
    canUpdate: Boolean,
    statusWillCloseCollaboration: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.days-text
  max-width 3ch
</style>
